.boxed-label {
  text-align: center;
  margin-bottom: 0;
}
.boxed-currency {
  border: 0.1rem solid #414f56;
  text-align: center;
  padding-top: 1rem;
  margin: 0 10%;
  min-height: 4rem;
}

.table-td-blue {
  background-color: #213863;
  color: #fff;
  border-left: 0.1rem solid #fff;
}
.table-td-red {
  background-color: #ee3924;
  color: #fff;
  border-left: 0.1rem solid #fff;
}
.table-td-red-text {
  color: #ee3924;
}
.table-th-blue {
  background-color: #213863;
  color: #fff;
  border-left: 0.1rem solid #fff;
}
.table-th-lt-gray {
  background-color: #adadad;
  color: #fff;
  border-left: 0.1rem solid #fff;
}
.table-th-dk-gray {
  background-color: #707070;
  color: #fff;
  border-left: 0.1rem solid #fff;
}

.nav-tabs > li {
  font-size: 1.2rem;
  margin-top: 0.4rem;
}
.nav-tabs > li > a {
  background-color: #e2e5e7;
  border-radius: 3.2rem 3.2rem 0 0;
}

th {
  text-align: center;
}
