.table-alt-row {
    background: rgb(250, 250, 250);
}
.editable-cell {
    position: relative;
}

.editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
}

.editable-row:hover.editable-cell-value-wrap {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 4px 11px;
}

.arbitrage .ant-table-tbody > tr > td {
    padding: 3px 3px;
}
td.percent,
td.currency {
  text-align: right;
}
td.totals {
  font-weight: 700;
}
td.stocks {
  text-transform: capitalize;
  font-weight: bold;
  background-color: #6cacdd !important;
}
td.bonds {
  text-transform: capitalize;
  font-weight: bold;
  background-color: #f2903f !important;
}
td.IUL,
td.hybrid {
  text-transform: capitalize;
  font-weight: bold;
  background-color: #cccccc !important;
}
td.annuities {
  text-transform: capitalize;
  font-weight: bold;
  background-color: #ebb510 !important;
}
td.cash {
  text-transform: capitalize;
  font-weight: bold;
  background-color: #3e6ec4 !important;
}
input.text {
  font-size: 12px;
  line-height: 12px;
  padding: 0 4px !important;
  border: None;
  border-bottom: #cccccc solid 2px;
  box-shadow: None;
}
input:focus {
  border-color: None !important;
  border: None !important;
  outline: None !important;
  background-color: #fdfbbf;
}
button:focus {
  border-color: None !important;
  border: None !important;
  outline: None !important;
}
.input-group.cashflow {
  width: 104px;
}
input.required {
  border-color: #343434;
}
input.numberinput {
  padding-top: 0;
  text-align: right;
}
input.number {
  font-size: 12px;
  line-height: 12px;
  padding: 0 4px !important;
  border: None;
  border-bottom: #cccccc solid 2px;
  box-shadow: None;
  text-align: right;
}
input.error {
  border: None;
  border-bottom: #ff1600 solid 2px;
  background-color: #eecccc;
  box-shadow: None;
}
input.percententry {
  text-align: right;
  width: 50px;
  height: 30px;
  margin-left: 0;
}
input.invested {
  width: 100px;
  text-align: right;
  margin-right: 20px;
  margin-left: 10px;
}
input.submit-btn {
  padding: 4px 20px;
  font-size: 14px;
  border-radius: 4px;
  margin: 0 0 4px 8px;
}
tr:nth-child(odd) > td.positive {
  background-color: #d9f5c8 !important;
}
tr:nth-child(odd) > td.negative {
  background-color: #ffd5d0 !important;
}
tr:nth-child(even) > td.positive {
  background-color: #d9f5c8 !important;
}
tr:nth-child(even) > td.negative {
  background-color: #ffd5d0 !important;
}

.dropzone {
  text-align: center;
  vertical-align: middle;
  font-size: 2.5em;
  padding-top: 65px;
  margin: 10px 0;
  width: 100%;
  height: 200px;
  border: dashed 2px black;
  background-color: #eee;
}

.dropzone-active {
  background-color: lightyellow;
}

.dropzone-accept {
  background-color: lightgreen;
}

.dropzone-reject {
  background-color: lightsalmon;
}

.table-condensed .ant-table-thead > tr > th, 
.table-condensed .ant-table-tbody > tr > td {
    padding: 2px 2px !important;
}
.boxed-label {
  text-align: center;
  margin-bottom: 0;
}
.boxed-currency {
  border: 0.1rem solid #414f56;
  text-align: center;
  padding-top: 1rem;
  margin: 0 10%;
  min-height: 4rem;
}

.table-td-blue {
  background-color: #213863;
  color: #fff;
  border-left: 0.1rem solid #fff;
}
.table-td-red {
  background-color: #ee3924;
  color: #fff;
  border-left: 0.1rem solid #fff;
}
.table-td-red-text {
  color: #ee3924;
}
.table-th-blue {
  background-color: #213863;
  color: #fff;
  border-left: 0.1rem solid #fff;
}
.table-th-lt-gray {
  background-color: #adadad;
  color: #fff;
  border-left: 0.1rem solid #fff;
}
.table-th-dk-gray {
  background-color: #707070;
  color: #fff;
  border-left: 0.1rem solid #fff;
}

.nav-tabs > li {
  font-size: 1.2rem;
  margin-top: 0.4rem;
}
.nav-tabs > li > a {
  background-color: #e2e5e7;
  border-radius: 3.2rem 3.2rem 0 0;
}

th {
  text-align: center;
}

/***************************
* Buckets                  *
***************************/

.side-scroller {
    display: flex;
    width: auto;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
}

.bucket-top-menu {
    height: 30px;
    background-color: #f5f5f5;
    color: #999;
    text-align: center;
    margin-bottom: 10px;
    line-height: 30px;
}
.bucket-name {
    font-weight: 700;
    text-align: left;
    border-bottom: #343434 solid 2px;
    margin: 0;
    margin-bottom: 2px;
    height: 22px;
    width: 100px;
}
.bucket-name-text {
    text-align: left;
}
.bucket-delete {
    color: #cccccc;
    float: right;
    margin-right: 3px;
    cursor: pointer;
    width: 12.85px;
    z-index: 100;
}
.bucket-delete:hover {
    color: rgba(255, 0, 0, 0.51);
}
.bucket-labels {
    width: 130px;
    float: left;
    padding-top: 26px;
    margin-right: 4px;
}
.bucket-label {
    line-height: 20px;
    font-size: 13px;
    margin: 0 0 10px;
}
.bucket-values {
    width: 110px;
    margin-right: 18px;
    margin-left: 4px;
    display: inline-block;
    height: 300px;
}
.bucket-values-add {
    display: inline-block;
    color: #ccc;
    margin: auto;
}
.bucket-add-segment-button {
    width: 100px;
    height: 290px;
    background-color: white;
    border: none;
}

.bucket-add-segment-button:hover {
    cursor: pointer;
    color: rgb(69, 121, 99);
}

.bucket-enter {
    opacity: 0.01;
}
.bucket-enter.bucket-enter-active {
    opacity: 1;
    transition: opacity 0.5s ease-in;
}
.bucket-leave {
    opacity: 1;
}
.bucket-leave.bucket-leave-active {
    opacity: 0.01;
    transition: opacity 0.5s ease-in;
}

body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

@-webkit-keyframes placeHolderShimmer {
    0% {
        background-position: -468px 0;
    }
    100% {
        background-position: 468px 0;
    }
}

@keyframes placeHolderShimmer {
    0% {
        background-position: -468px 0;
    }
    100% {
        background-position: 468px 0;
    }
}

.animated-background {
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-name: placeHolderShimmer;
            animation-name: placeHolderShimmer;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 800px 104px;
    height: 96px;
    position: relative;
}

footer {
    background-color: #eeeeee;
    height: 60px;
    padding: 20px 10px;
}

td.text-centered {
    text-align: center;
}

h4.h4-red {
    color: white;
    background: red;
    text-align: center;
    padding: 5px;
    margin: 5px;
}

h4.h4-blue {
    color: white;
    background: #003e80;
    text-align: center;
    padding: 5px;
    margin: 5px;
}

.col-fill-blue {
    background: #003e80;
    color: #ffffff;
}

.col-fill-red {
    background: red;
    color: #ffffff;
}

.col-full-border {
    border: 2px solid black;
}

td.red,
th.red {
    color: white;
    background: red;
}

td.blue,
th.blue {
    color: white;
    background: #003e80;
}

h4.white {
    color: white !important;
}

h3.white {
    color: white !important;
}

h5.white {
    color: white !important;
}

p.blue,
p.fill {
    border: 2px solid #000000;
    padding: 5px;
    color: #003e80;
    font-size: 20px;
}

p.red,
p.fill {
    border: 2px solid #000000;
    padding: 5px;
    color: red;
    font-size: 20px;
}

.table-600 {
    width: 600px;
}

.table-800 {
    width: 800px;
}

.table-noborder td {
    border: none;
}

.table-noborder tr {
    border: none;
}

.table-noborder thead tr th {
    border: none;
}

.table-th-bordertop {
    border-top: 2px solid black !important;
}

.table-th-bordertopleft {
    border-left: 2px solid black !important;
    border-top: 2px solid black !important;
}

.table-th-bordertopright {
    border-right: 2px solid black !important;
    border-top: 2px solid black !important;
}

.table-th-borderbottomright {
    border-right: 2px solid black !important;
    border-bottom: 2px solid black !important;
}

.table-th-borderbottom {
    border-bottom: 2px solid black !important;
}

.table-noborder td.currency {
    border: none;
    border-top: none;
    padding: 1px 8px 1px 8px;
}

td.bold {
    font-weight: bold;
}

div.center {
    display: flex;
    justify-content: center;
    align-content: center;
    align-self: center;
    flex-wrap: wrap;
}

.table-td-borderright,
.table-th-borderright {
    border-right: 2px solid black !important;
}

.table-td-borderleft,
.table-th-borderleft {
    border-left: 2px solid black !important;
}

.table-td-sum {
    background-color: #b8d5e0;
}

.table-td-space {
    border: none;
    border: 0px !important;
}

.table-td-noborder {
    border: none;
    border: 0px !important;
    border-top: 0px !important;
}

.table-td-clearborder {
    border: none !important;
    border-top: none !important;
    border-bottom: none !important;
    border-left: none !important;
    border-right: none !important;
}

.table-th-clearborder {
    border: none !important;
    border-top: none !important;
    border-bottom: none !important;
    border-left: none !important;
    border-right: none !important;
}

.table-tr-noborder {
    border: none;
}

.table-td-bold {
    font-weight: bold;
}

.container800 {
    width: 800px;
}

.container600 {
    width: 600px;
}

.container700 {
    width: 700px;
}

.container1000 {
    width: 1000px;
}

.max-card {
    flex-wrap: wrap;
    padding: 10;
    width: 400px;
}

.disclaimer-800 {
    width: 800px;
    color: grey;
    font-size: 10px;
    text-align: left;
    text-justify: auto;
}

.disclaimer {
    color: grey;
    font-size: 10px;
    text-justify: auto;
}

td.red-text {
    color: red;
}

h3.dark_blue-600 {
    width: 600px;
    color: #003e80;
}

.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
    border: none;
}

/***************************
* Income Comp              *
***************************/

table.ic-rmdcomp-advantages {
    margin-top: 30px;
}

table.ic-rmdcomp-advantages > thead > tr > td,
table.ic-rmdcomp-advantages > tbody > tr > td {
    padding: 4px;
    margin-top: 10px;
    width: 200px;
}

table.ic-rmdcomp-advantages > thead > tr > td.empty-col,
table.ic-rmdcomp-advantages > tbody > tr > td.empty-col {
    width: 80px;
}

table.table-cell-pad > thead > tr > th,
table.table-cell-pad > thead > tr > td,
table.table-cell-pad > tbody > tr > td,
table.table-cell-pad > tfoot > tr > td {
    padding: 1px 0 1px 2px;
}

table.ic-estatetax-table > tbody > tr > td,
table.ic-estatetax-table > thead > tr > td,
table.ic-estatetax-table > thead > tr > th,
table.ic-taxcalc-table > tbody > tr > td {
    padding: 0 8px;
}

table.inherited-summary-table {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 70%;
}

table.inherited-summary-table > tbody > tr > td {
    padding: 4px;
}

table.table-inherited {
    width: 100%;
}

table.ic-rmdcomp-table {
    border-collapse: separate;
    margin-top: 30px;
}

table.ic-taxcalc-table > tbody > tr.empty-row,
table.ic-estatetax-table > tbody > tr.empty-row,
table.ic-rmdcomp-table > tbody > tr.empty-row {
    height: 24px;
}

table.ic-rmdcomp-table > tbody > tr > td.empty-col {
    width: 24px;
}

table.ic-rmdcomp-table > tbody > tr > th {
    vertical-align: bottom;
}

table.ic-rmdcomp-table > tbody > tr {
    border: none;
}

input.ic-taxcalc-input,
input.ic-state-tax-input {
    background: none;
    text-align: right;
}

input.ic-taxcalc-input::-webkit-outer-spin-button,
input.ic-state-tax-input::-webkit-outer-spin-button,
input.ic-taxcalc-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input.ic-state-tax-input {
    width: 80px;
    justify-self: end;
    margin-left: 20px;
    background-color: #ffffcc;
}

div.inherited-tables {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

div.inherited-table {
    margin-top: 20px;
    width: 45%;
}

div.input-percent {
    position: relative;
    width: 100%;
    display: inline-block;
}

div.input-percent span.percent {
    position: absolute;
    top: 36px;
    right: 28px;
    line-height: 100%;
    vertical-align: middle;
}

input.input-num-percent {
    text-align: right;
    padding-right: 15px;
}

input.input-num {
    text-align: right;
}

input.is-invalid,
input.is-invalid:focus {
    background-color: #fdcdcd;
}

.table-text-header {
    color: #203764;
    font-weight: bold;
    font-size: 22px;
    margin-top: 10px;
}

.table-text-subheader {
    color: #203764;
    font-weight: bold;
    font-size: 16px;
}

.form-fields-header {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 20px;
}

.status-bar {
    height: 6px;
    background-color: green;
}

.status-bar-saving {
    background-color: gold;
}

.status-bar-error {
    background-color: darkred;
}

.text-right {
    text-align: right;
}

.text-ital {
    font-style: italic;
}

.text-bottom {
    vertical-align: bottom;
}

.text-center {
    text-align: center;
}

.text-number {
    text-align: right;
    padding-left: 4px;
    padding-right: 11px !important;
}

.text-red {
    color: #ff0000;
}

.ic-gray-bgnd {
    background-color: #d0cece;
}

.text-blue {
    color: #305496;
}

.text-bold {
    font-weight: bold;
}

.text-h1-spacing {
    margin-bottom: 0.25em;
    margin-top: 0.25em;
}

.center-content {
    display: flex;
    justify-content: center;
}

.text-bubble-white-on-blue {
    font-weight: bold;
    font-size: 18px;
    color: #fff;
    background-color: #4472c4;
    border: 2px solid #305496;
    border-radius: 20px;
    padding: 20px;
    margin: 10px;
}

.text-bubble-blue-on-white {
    font-weight: bold;
    font-size: 24px;
    color: #305496;
    background-color: #fff;
    border: 2px solid #305496;
    border-radius: 20px;
    padding: 20px;
    margin: 10px;
}

.ic-very-light-gray-bgnd {
    background-color: #d0cece;
}

.ic-mercury-bgnd {
    background-color: #e7e6e6;
}

.ic-cyan-bgnd {
    background-color: #ccffff;
}

.ic-yellow-bgnd {
    background-color: #ffffcc;
}

.ic-light-green-bgnd {
    background-color: #e2efda;
}

.ic-green-bgnd {
    background-color: #c6e0b4;
}

.ic-dark-green-bgnd {
    background-color: #a9d08f;
}

.ic-blue-bgnd {
    background-color: #d9e1f2;
}

.ic-dark-blue-bgnd {
    background-color: #b4c6e7;
}

.ic-orange-bgnd {
    background-color: #f8cbad;
}

.ic-dark-orange-bgnd {
    background-color: #f4b085;
}

.ic-4-borders {
    border: 1px solid #000000;
}

.ic-top-border {
    border-top: 1px solid #000000;
}

.ic-bottom-border {
    border-bottom: 1px solid #000000;
}

.ic-left-border {
    border-left: 1px solid #000000;
}

.ic-right-border {
    border-right: 1px solid #000000;
}

.arrow-right-blue {
    width: 0;
    height: 0;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;

    border-left: 12px solid #2f5597;
}

.arrow-left-blue {
    width: 0;
    height: 0;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;

    border-right: 12px solid #2f5597;
}

table.atap-policy-cost-detail {
    border: 1px solid black;
}

table.atap-policy-cost-detail > thead > tr > th {
    border-bottom: 1px solid black;
    text-align: center;
    vertical-align: bottom;
    padding: 8px;
}

table.atap-policy-cost-detail > tfoot > tr > th {
    border-bottom: 1px solid black;
    text-align: center;
    vertical-align: bottom;
    padding: 4px;
}

table.atap-policy-cost-detail > tfoot > tr > td {
    border-bottom: 1px solid black;
    text-align: right;
    padding: 4px;
}

table.atap-policy-cost-detail > tbody > tr > td {
    text-align: right;
    padding: 4px;
}

th.col-fill-aria-yellow,
td.col-fill-aria-yellow {
    background-color: #fff2cc;
}

th.col-fill-aria-blue,
td.col-fill-aria-blue {
    background-color: #d9e1f2;
}

th.col-fill-aria-green,
td.col-fill-aria-green {
    background-color: #c6e0b4;
}

tr.col-fill-aria-dkgreen {
    background-color: #abc19b;
    border: 1px solid black;
}

tr.col-fill-aria-dkblue > td,
tr.col-fill-aria-dkgreen > td {
    padding: 4px;
}

tr.col-fill-aria-dkblue {
    background-color: #9babc7;
    border: 1px solid black;
}

table.atap-summary {
    margin: auto;
}

table.atap-summary > tbody > tr > td {
    padding: 4px;
}

table.atap-summary > tbody > tr > td.fill-blue {
    color: #fff;
    background-color: #305496;
}
table.atap-summary > tbody > tr > td.fill-green {
    color: #fff;
    background-color: #00b050;
}
table.atap-summary > tbody > tr > td.fill-gray {
    color: #fff;
    background-color: #808080;
}

table.atap-iul {
    width: 800;
    border-left: 2px solid black;
    border-right: 2px solid black;
    margin: auto;
}

table.atap-iul > thead > tr > th {
    background-color: #305496;
    color: #fff;
    padding: 8px;
    text-align: center;
    vertical-align: bottom;
}

table.atap-iul > tbody > tr > td:nth-child(4),
table.atap-iul > thead > tr > th:nth-child(4) {
    border-left: 2px solid black;
}

table.atap-iul > tbody > tr > td {
    padding: 8px;
    text-align: right;
}
.loader-container {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}
.loader {
    opacity: 0.5;
    z-index: 1000;
    -webkit-animation: heart-pulse 3s infinite ease-in;
            animation: heart-pulse 3s infinite ease-in;
}

@-webkit-keyframes heart-pulse {
    0% {
        -webkit-transform: scale(0.6);
                transform: scale(0.6); /* scaling to 0 */
    }
    50% {
        -webkit-transform: scale(0.65);
                transform: scale(0.65); /* increasing the size */
    }
    100% {
        -webkit-transform: scale(0.6);
                transform: scale(0.6); /* seeting back to initial size */
    }
}

@keyframes heart-pulse {
    0% {
        -webkit-transform: scale(0.6);
                transform: scale(0.6); /* scaling to 0 */
    }
    50% {
        -webkit-transform: scale(0.65);
                transform: scale(0.65); /* increasing the size */
    }
    100% {
        -webkit-transform: scale(0.6);
                transform: scale(0.6); /* seeting back to initial size */
    }
}

