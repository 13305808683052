td.percent,
td.currency {
  text-align: right;
}
td.totals {
  font-weight: 700;
}
td.stocks {
  text-transform: capitalize;
  font-weight: bold;
  background-color: #6cacdd !important;
}
td.bonds {
  text-transform: capitalize;
  font-weight: bold;
  background-color: #f2903f !important;
}
td.IUL,
td.hybrid {
  text-transform: capitalize;
  font-weight: bold;
  background-color: #cccccc !important;
}
td.annuities {
  text-transform: capitalize;
  font-weight: bold;
  background-color: #ebb510 !important;
}
td.cash {
  text-transform: capitalize;
  font-weight: bold;
  background-color: #3e6ec4 !important;
}
input.text {
  font-size: 12px;
  line-height: 12px;
  padding: 0 4px !important;
  border: None;
  border-bottom: #cccccc solid 2px;
  box-shadow: None;
}
input:focus {
  border-color: None !important;
  border: None !important;
  outline: None !important;
  background-color: #fdfbbf;
}
button:focus {
  border-color: None !important;
  border: None !important;
  outline: None !important;
}
.input-group.cashflow {
  width: 104px;
}
input.required {
  border-color: #343434;
}
input.numberinput {
  padding-top: 0;
  text-align: right;
}
input.number {
  font-size: 12px;
  line-height: 12px;
  padding: 0 4px !important;
  border: None;
  border-bottom: #cccccc solid 2px;
  box-shadow: None;
  text-align: right;
}
input.error {
  border: None;
  border-bottom: #ff1600 solid 2px;
  background-color: #eecccc;
  box-shadow: None;
}
input.percententry {
  text-align: right;
  width: 50px;
  height: 30px;
  margin-left: 0;
}
input.invested {
  width: 100px;
  text-align: right;
  margin-right: 20px;
  margin-left: 10px;
}
input.submit-btn {
  padding: 4px 20px;
  font-size: 14px;
  border-radius: 4px;
  margin: 0 0 4px 8px;
}
tr:nth-child(odd) > td.positive {
  background-color: #d9f5c8 !important;
}
tr:nth-child(odd) > td.negative {
  background-color: #ffd5d0 !important;
}
tr:nth-child(even) > td.positive {
  background-color: #d9f5c8 !important;
}
tr:nth-child(even) > td.negative {
  background-color: #ffd5d0 !important;
}
