/***************************
* Buckets                  *
***************************/

.side-scroller {
    display: flex;
    width: auto;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
}

.bucket-top-menu {
    height: 30px;
    background-color: #f5f5f5;
    color: #999;
    text-align: center;
    margin-bottom: 10px;
    line-height: 30px;
}
.bucket-name {
    font-weight: 700;
    text-align: left;
    border-bottom: #343434 solid 2px;
    margin: 0;
    margin-bottom: 2px;
    height: 22px;
    width: 100px;
}
.bucket-name-text {
    text-align: left;
}
.bucket-delete {
    color: #cccccc;
    float: right;
    margin-right: 3px;
    cursor: pointer;
    width: 12.85px;
    z-index: 100;
}
.bucket-delete:hover {
    color: rgba(255, 0, 0, 0.51);
}
.bucket-labels {
    width: 130px;
    float: left;
    padding-top: 26px;
    margin-right: 4px;
}
.bucket-label {
    line-height: 20px;
    font-size: 13px;
    margin: 0 0 10px;
}
.bucket-values {
    width: 110px;
    margin-right: 18px;
    margin-left: 4px;
    display: inline-block;
    height: 300px;
}
.bucket-values-add {
    display: inline-block;
    color: #ccc;
    margin: auto;
}
.bucket-add-segment-button {
    width: 100px;
    height: 290px;
    background-color: white;
    border: none;
}

.bucket-add-segment-button:hover {
    cursor: pointer;
    color: rgb(69, 121, 99);
}

.bucket-enter {
    opacity: 0.01;
}
.bucket-enter.bucket-enter-active {
    opacity: 1;
    transition: opacity 0.5s ease-in;
}
.bucket-leave {
    opacity: 1;
}
.bucket-leave.bucket-leave-active {
    opacity: 0.01;
    transition: opacity 0.5s ease-in;
}
