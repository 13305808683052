.dropzone {
  text-align: center;
  vertical-align: middle;
  font-size: 2.5em;
  padding-top: 65px;
  margin: 10px 0;
  width: 100%;
  height: 200px;
  border: dashed 2px black;
  background-color: #eee;
}

.dropzone-active {
  background-color: lightyellow;
}

.dropzone-accept {
  background-color: lightgreen;
}

.dropzone-reject {
  background-color: lightsalmon;
}
